import { map } from '@laufire/utils/collection';
import React from 'react';

const contents = ['Find and hire top talent, quickly.',
	'Help build diverse teams.',
	'Ensure most qualified candidate capable of delivering results.',
	'Help organizations of all sizes in acquiring top-tier talent.'];

const Staffing = () =>
	<div className="centerPara">
		<div>Staffing Solutions</div>
		<div className="service">
			{map(contents, (content, i) =>
				<p key={ i } className="">
					{content}
				</p>)}
		</div>
	</div>;

export default Staffing;
