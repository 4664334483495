import { map } from '@laufire/utils/collection';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import Client from './Client';

const Clients = (context) => {
	const { config: { clients }, data, setState } = context;
	const { ref, inView } = useInView({
		threshold: 0.55,
		onChange: () =>
			inView && setState((prev) => ({ ...prev, active: data })),
	});

	return (
		<div ref={ ref } className="center">
			<h3 className="highlightText">Our Happy Customers</h3>
			<div className="clientParent centerItems">
				{map(clients, (client, i) =>
					<Client key={ i } { ...{ ...context, data: client } }/>)}
			</div>
		</div>
	);
};

export default Clients;
