import React from 'react';
import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import updateScroll from '../updateScroll';

const MenuItem = (context) => {
	const {
		item: { name }, item, parallaxRef,
	} = context;

	return <ListItem className="menuSize">
		<ListItemButton onClick={ () => updateScroll(parallaxRef, item) }>
			<ListItemText primary={ name }/>
		</ListItemButton>
	</ListItem>;
};

export default MenuItem;
