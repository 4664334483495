import React from 'react';
import { Divider, List, Drawer as MuiDrawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuOptions from '../MenuOptions';
import MenuItem from './MenuItem.js';

const Header = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar,
	justifyContent: 'space-between',
}));

const handleMenuAction = ({ setState, state: { open }}) =>
	setState((prev) => ({ ...prev, open: !open }));

const MenuHeader = (context) => {
	const { config: { publicImage }} = context;

	return <Header>
		<img
			src={ `${ publicImage }/OrangeLogo.svg` }
			alt="logo"
			className="drawerLogo"
		/>
		<ChevronLeftIcon onClick={ () => handleMenuAction(context) }/>
	</Header>;
};

const HamburgerMenu = (context) => {
	const { state: { open }} = context;

	return (
		<div className="drawer">
			<MenuIcon onClick={ () => handleMenuAction(context) }/>
			<MuiDrawer
				open={ open }
				onClose={ () => handleMenuAction(context) }
			>
				<MenuHeader { ...context }/>
				<Divider/>
				<List onClick={ () => handleMenuAction(context) }>
					<MenuOptions { ...{ ...context, MenuItem } }/>
				</List>
			</MuiDrawer>
		</div>);
};

export default HamburgerMenu;
