import React from 'react';
import HamburgerMenu from './HamburgerMenu';
import FloatingMenu from './FloatingMenu';

const Header = (context) => {
	const { config: { publicImage }, isBrowser, parallaxRef } = context;
	const Menu = isBrowser ? FloatingMenu : HamburgerMenu;
	const scrollTop = () => parallaxRef.current.scrollTo(0);

	return (
		<div className="sticky header">
			<div className="logo centerItems">
				<img
					src={ `${ publicImage }/OrangeLogo.svg` }
					alt="logo"
					onClick={ scrollTop }
				/>
				<span
					className="logoHighlight"
					onClick={ scrollTop }
				>ABS</span>
			</div>
			<Menu { ...context }/>
		</div>);
};

export default Header;
