import React from 'react';
import { InView } from 'react-intersection-observer';
import { Spring, animated } from 'react-spring';
import Services from './Services.js';

const getSpringProps = (inView) => ({
	config: { duration: 1000 },
	from: {	clipPath: 'inset(50% 10%)' },
	to: {
		clipPath: inView
			? 'inset(4% 7%)'
			: 'inset(40% 10%)',
	},
});

const getAnimationProps = (ref, props) => ({
	ref: ref,
	className: 'center serviceContainer',
	style: { ...props },
});

const ServiceAnimation = (context) =>
	<InView threshold={ 0 }>
		{({ inView, ref }) =>
			<Spring { ...getSpringProps(inView) }>
				{(props) =>
					<animated.div { ...getAnimationProps(ref, props) }>
						<Services { ...context }/>
					</animated.div>}
			</Spring>}
	</InView>;

export default ServiceAnimation;
