import React from 'react';
import { useInView } from 'react-intersection-observer';
import TechImages from './TechImages';

const Technologies = (context) => {
	const { isBrowser, setState, data } = context;
	const width = isBrowser ? '60%' : '80%';
	const { ref, inView } = useInView({
		threshold: 0.55,
		onChange: () =>
			inView && setState((prev) => ({ ...prev, active: data })),
	});

	return (
		<div
			ref={ ref }
			className="techParent"
			style={ { width } }
		>
			<h3 className="highlightText">Our Expertise</h3>
			<TechImages { ...context }/>
		</div>
	);
};

export default Technologies;
