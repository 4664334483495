import { React, Fragment } from 'react';
import GlassLayer from './GlassLayer';
import Shapes from './Shapes';

const Home = (context) =>
	<Fragment>
		<GlassLayer { ...context }/>
		<Shapes { ...context }/>
	</Fragment>;

export default Home;
