import React from 'react';
import TeamSlider from './TeamSlider.js';
import { useInView } from 'react-intersection-observer';

const Profile = (context) => {
	const { config: { employees }, state: { currentEmployee }} = context;
	const {	role }	= employees[currentEmployee];

	return (
		<div className="profileContainer glass">
			<div className="role">{role}</div>
		</div>
	);
};

const TeamContent = () =>
	<div className="teamContent">
		We are a team of <span className="highlight">
			20 from India</span>, striving to build
		custom software solutions for our clients across
		the globe, with high efficiency and premium
		quality.
	</div>;

const Team = (context) => {
	const { setState, data } = context;
	const { ref, inView } = useInView({
		threshold: 0.55,
		onChange: () =>
			inView && setState((prev) => ({ ...prev, active: data })),
	});

	return (
		<div ref={ ref } className="team">
			<TeamContent/>
			<div className="teamHolder">
				<TeamSlider { ...context }/>
				<Profile { ...context }/>
			</div>
		</div>
	);
};

export default Team;
