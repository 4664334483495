import React from 'react';
import { Swiper, SwiperSlide }	from 'swiper/react';
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/effect-coverflow';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';
import { map } from '@laufire/utils/collection';
import nameTagBg from '../../images/name-tag-bg.png';

const half = 0.5;
const updateEmployeeIndex = (setState) => ({ realIndex }) =>
	setState((prev) => ({ ...prev, currentEmployee: realIndex }));

const NameDisplay = ({ first, last }) =>
	<div className="name">
		<svg height="50">
			<text
				fill="#fff"
				x="50%"
				y="50%"
				dominantBaseline="middle"
				textAnchor="middle"
			>
				{first}{last}
			</text>
		</svg>
	</div>;

const Slide = ({ pic, firstName, nickName, lastName }) =>
	<span>
		<img
			src={ pic }
			alt="profileImage"
			className="employeeImage"
		/>
		<div className="center ImageText">{nickName}</div>
		<img
			src={ nameTagBg }
			alt="profileImage"
			className="nameTagBg"
		/>
		<NameDisplay
			first={ firstName }
			last={ lastName }
		/>
	</span>;

const props = {
	effect: 'coverflow',
	grabCursor: true,
	centeredSlides: true,
	slidesPerView: 'auto',
	navigation: true,
	pagination: { clickable: true },
	coverflowEffect: {
		rotate: 0,
		stretch: 80,
		depth: 100,
		modifier: 2,
	},
	modules: [EffectCoverflow, Pagination, Navigation],
};

const Employee = (employee, i) =>
	<SwiperSlide key={ i }>
		<Slide { ...employee }/>
	</SwiperSlide>;

const TeamSlider = ({ config: { employees }, setState }) => {
	const centerSlideNo = Math.round(employees.length * half - 1);

	return (
		<div className="swiperCarousel">
			<Swiper
				{
					...{
						...props,
						initialSlide: centerSlideNo,
						onSlideChange: updateEmployeeIndex(setState),
					}
				}
			>
				{map(employees, Employee)}
			</Swiper>
		</div>
	);
};

export default TeamSlider;
