import Services from '../components/Services';
import Technologies from '../components/Technologies';
import Team from '../components/Team';
import Clients from '../components/Clients';
import Home from '../components/Home';
import Footer from '../components/Footer';

const pages = {
	'Home': Home,
	'Services': Services,
	'Technologies': Technologies,
	'Clients': Clients,
	'About Us': Team,
	'Footer': Footer,
};

export default pages;
