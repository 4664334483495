import React, { useRef, createRef, useEffect } from 'react';
import { gsap } from 'gsap';
import MenuOptions from '../MenuOptions';
import MenuItem from './MenuItem.js';

const animate = ({ root, indicatorOne, indicatorTwo,
	items, state: { active }}) => {
	const menuOffset = root.current.getBoundingClientRect();
	const activeItem = items.current[active].current;
	const { width, height, top, left } = activeItem.getBoundingClientRect() ;
	const settings = {
		x: left - menuOffset.x,
		y: top - menuOffset.y,
		width: width,
		height: height,
		ease: 'circ.easeout',
		backgroundColor: 'hsla(16,76%,53%,1)',
		duration: 2,
	};

	gsap.to(indicatorOne.current, { ...settings });

	gsap.to(indicatorTwo.current, { ...settings, duration: 1.5 });
};

const Menu = (context) => {
	const {
		root,
		indicatorOne,
		indicatorTwo,
	} = context;

	return (
		<div ref={ root } className="menu">
			<MenuOptions { ...{ ...context, MenuItem } }/>
			<div ref={ indicatorOne } className="indicator"/>
			<div ref={ indicatorTwo } className="indicator"/>
		</div>
	);
};

const FloatingMenu = (context) => {
	const { config: { pageItems }, state: { active }} = context;
	const root = useRef();
	const indicatorOne = useRef();
	const indicatorTwo = useRef();
	const items = useRef(pageItems.map(createRef));
	const extendedContext
	= { ...context, root, items, indicatorOne, indicatorTwo };

	useEffect(() => {
		animate(extendedContext);
		const handleAnimation = () => animate(extendedContext);

		window.addEventListener('resize', handleAnimation);

		return () => window.removeEventListener('resize', handleAnimation);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [active]);

	return <Menu { ...extendedContext }/>;
};

export default FloatingMenu;
