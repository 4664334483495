/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines */
import kpkPic from '../images/kpk-pic-abs.jpg';
import nkgPic from '../images/nkg-pic-abs.jpg';
import pdnPic from '../images/pdn-pic-abs.jpg';
import rdrnPic from '../images/rdrn-pic-abs.jpg';
import dummyPic from '../images/dummy-profile1.jpg';

const config = {
	publicImage: process.env.PUBLIC_URL,
	employees: [
		{
			firstName: 'Abdul ',
			lastName: 'Rahim S',
			role: 'Software Developer',
			experience: '2',
			pic: dummyPic,
			nickName: 'Invisible but Invincible',
		},
		{
			firstName: 'Durai ',
			lastName: 'Murugan D',
			role: 'Junior Software Developer',
			experience: '1.5',
			pic: dummyPic,
			nickName: 'Invisible but Invincible',
		},
		{
			firstName: 'Indupriyadharshini ',
			lastName: 'R',
			role: 'Software Developer',
			experience: '4',
			pic: dummyPic,
			nickName: 'Invisible but Invincible',
		},
		{
			firstName: 'Jayasutha ',
			lastName: 'K',
			role: 'Operations Manager',
			experience: '4',
			pic: dummyPic,
			nickName: 'Invisible but Invincible',
		},
		{
			firstName: 'Karpagam ',
			lastName: 'K',
			role: 'Sr. Software Developer',
			experience: '4',
			pic: kpkPic,
		},
		{
			firstName: 'Madhuri ',
			lastName: 'R',
			role: 'HR Recruiter',
			experience: '2',
			pic: dummyPic,
			nickName: 'Invisible but Invincible',
		},
		{
			firstName: 'Meenakshi ',
			lastName: 'V',
			role: 'Head Of Finance',
			experience: '1.5',
			pic: dummyPic,
			nickName: 'Invisible but Invincible',
		},
		{
			firstName: 'Nachiyammai ',
			lastName: 'Al',
			role: 'Software Developer',
			experience: '4',
			pic: dummyPic,
			nickName: 'Invisible but Invincible',
		},
		{
			firstName: 'Nirupan ',
			lastName: 'Joe J',
			role: 'Software Trainer',
			experience: '2',
			pic: dummyPic,
			nickName: 'Invisible but Invincible',
		},
		{
			firstName: 'Nithesh Kumar ',
			lastName: 'G',
			role: 'Sr. Software Developer',
			experience: '2',
			pic: nkgPic,
		},
		{
			firstName: 'Priyadarshini ',
			lastName: 'N',
			role: 'Software Developer',
			experience: '1.5',
			pic: pdnPic,
		},
		{
			firstName: 'Radhika ',
			lastName: 'R',
			role: 'Software Developer',
			experience: '4',
			pic: rdrnPic,
		},
		{
			firstName: 'Periyal ',
			lastName: 'L',
			role: 'Operations Executive',
			experience: '1.5',
			pic: dummyPic,
			nickName: 'Invisible but Invincible',
		},
		{
			firstName: 'Prakash ',
			lastName: 'Mohan',
			role: 'Finance Consultant',
			experience: '4',
			pic: dummyPic,
			nickName: 'Invisible but Invincible',
		},
		{
			firstName: 'Ramkumar ',
			lastName: 'Ct',
			role: 'Head Of Operations',
			experience: '2',
			pic: dummyPic,
			nickName: 'Invisible but Invincible',
		},
		{
			firstName: 'Ramkumar ',
			lastName: 'R	',
			role: 'Software Developer',
			experience: '1.5',
			pic: dummyPic,
			nickName: 'Invisible but Invincible',
		},
		{
			firstName: 'Sathiyendran ',
			lastName: 'G',
			role: 'UX Designer',
			experience: '2',
			pic: dummyPic,
			nickName: 'Invisible but Invincible',
		},
		{
			firstName: 'Sivakami ',
			lastName: 'Rm',
			role: 'Operations Executive',
			experience: '1.5',
			pic: dummyPic,
			nickName: 'Invisible but Invincible',
		},
		{
			firstName: 'Thaiyalnayaki ',
			lastName: 'Al',
			role: 'Operations Executive',
			experience: '4',
			pic: dummyPic,
			nickName: 'Invisible but Invincible',
		},
		{
			firstName: 'Velappan ',
			lastName: 'V',
			role: 'Advisor',
			experience: '4',
			pic: dummyPic,
			nickName: 'Invisible but Invincible',
		},
		{
			firstName: 'Vijayatha ',
			lastName: 'G',
			role: 'Operations Executive',
			experience: '4',
			pic: dummyPic,
			nickName: 'Invisible but Invincible',
		},
		{
			firstName: 'Viswanath ',
			lastName: 'Ct',
			role: 'Partner',
			experience: '4',
			pic: dummyPic,
			nickName: 'Invisible but Invincible',
		},
	],
	technologies: [
		{
			image: '/js.svg',
			alt: 'javascript',
		},
		{ image: '/nextjs.png',
			alt: 'nextJs' },
		{
			image: '/react.png',
			alt: 'React',
		},
		{
			image: '/node.png',
			alt: 'Nodejs',
		},
		{
			image: '/shellScript.png',
			alt: 'shellScript',
		},
		{
			image: '/html.png',
			alt: 'html',
		},
		{
			image: '/css.png',
			alt: 'css',
		},
		{
			image: '/python.png',
			alt: 'python',
		},
		{
			image: '/github.png',
			alt: 'gitHub',
		},
	],
	pageItems: [
		{
			name: 'Home',
			props: {
				sticky: { start: 0, end: 0.3 },
				offset: 0,
				speed: 0,
			},
			hasMenu: true,
		},
		{
			name: 'Services',
			props: {
				offset: 1,
				speed: 0,
			},
			hasMenu: true,
		},
		{
			name: 'Technologies',
			props: {
				offset: 2,
				speed: 0,
			},
			hasMenu: true,
		},
		{
			name: 'Clients',
			props: {
				offset: 3,
				speed: 0,
			},
			hasMenu: true,
		},
		{
			name: 'About Us',
			props: {
				offset: 4,
				speed: 0,
			},
			hasMenu: true,
		},
		{
			name: 'Footer',
			props: {
				style: { height: '7vh' },
				sticky: { start: 5, end: 5 },
			},
			hasMenu: false,
		},
	],
	clients: [
		{
			name: 'Excelra Knowledge Systems',
			image: '/Excelra.png',
			description: 'Bioinformatics and data curating services',
			size: 'scale(0.6)',
		},
		{
			name: 'Hafla',
			description: 'Premium event planning services',
			image: 'hafla.svg',
			size: 'scale(0.6)',
		},
		{
			name: 'CHOSEN',
			description: 'Beauty products for skin and hair',
			image: 'chosen.webp',
			size: 'scale(.95)',
		},
		{
			name: 'Sedin Technologies',
			description: 'Business consulting and IT outsourcing services',
			image: 'sedin.png',
			size: 'scale(1)',
		},
		{
			name: 'CLEAR',
			image: 'clear.png',
			size: 'scale(4.5)',
			description: 'Leading NGO for IT training for the disadvantaged ',
		},
		{
			name: 'SKS Hospitals',
			description: 'Making pets smile for the past two decades.',
			image: 'sks.png',
			size: 'scale(1.1)',
		},
	],
	shapes: {
		x: [10, 80],
		y: [10, 60],
		rotation: [-50, 50],
		size: [4, 17],
		offset: [0, 8],
		speed: [10, 80],
		position: [1, -3],
		opacity: {
			'-3': 0.7,
			'1': 0.3,
		},
		type: ['square', 'circle'],
	},
	shapesCount: 7,
};

export default config;
