import React from 'react';
import { map } from '@laufire/utils/collection';

const MenuOptions = (context) => 	{
	const { config: { pageItems }, MenuItem } = context;

	return map(pageItems, (item, i) => {
		const { hasMenu } = item;

		return hasMenu
			&& <MenuItem key={ i } { ...{ ...context, i, item } }/>;
	});
};

export default MenuOptions;
