import React from 'react';

const Client = ({ data }) => {
	const { image, name, description, size } = data;

	return <div className="clientWrapper centerItems">
		<div className="client">
			<div className="clientFront centerItems">
				<img
					style={ { transform: `${ size }` } }
					src={ image }
					alt={ name }
				/>
			</div>
			<div className="clientBack centerItems">
				<div className="clientDesc">{description}</div>
			</div>
		</div>
	</div>;
};

export default Client;
