import { ParallaxLayer } from '@react-spring/parallax';
import React from 'react';

const getStyle = ({
	data: { x, y, size, rotation, position },
	config: { shapes: { opacity }},
}) => ({
	rotate: `${ rotation }deg`,
	left: `${ x }%`,
	top: `${ y }%`,
	transform: `scale(${ size })`,
	opacity: opacity[position],
});

const Shape = (context) => {
	const { data: { offset, speed, type, position }} = context;

	return (
		<ParallaxLayer { ...{
			offset: offset,
			speed: speed,
			style: { zIndex: `${ position }` },
		} }
		>
			<div
				className={ `shape ${ type }` }
				style={ getStyle(context) }
			/>
		</ParallaxLayer>
	);
};

export default Shape;
