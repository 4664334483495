import React from 'react';
import updateScroll from '../updateScroll';

const updateActiveIndex = (setState, item) =>
	() => setState((prev) => ({ ...prev, active: item.props.offset }));

const MenuOption = (context) => {
	const { i: index, items, item, parallaxRef,
		state: { active }, setState } = context;
	const className = active === item.props.offset ? 'active' : '';

	return (
		<div
			ref={ items.current[index] }
			className={ (`item ${ className }`) }
			onClick={ () => updateScroll(parallaxRef, item) }
			onMouseDown={ updateActiveIndex(setState, item) }
		>
			{item.name}
		</div>
	);
};

export default MenuOption;
