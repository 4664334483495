import React from 'react';
import { ParallaxLayer } from '@react-spring/parallax';
import { useInView } from 'react-intersection-observer';
import Content from './Content';

const GlassLayer = (context) => {
	const { setState, data } = context;
	const { ref, inView } = useInView({
		threshold: 0.55,
		onChange: () =>
			inView && setState((prev) => ({ ...prev, active: data })),
	});

	return <div ref={ ref } className="mainContainer glass center">
		<ParallaxLayer offset={ 0.07 } speed={ 1.5 } style={ { zIndex: 2 } }>
			<Content { ...context }/>
		</ParallaxLayer>
	</div>;
};

export default GlassLayer;
