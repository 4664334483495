import { React, useRef } from 'react';
import './App.scss';
import Body from './components/Body.js';
import Header from './components/Header';
import { useMediaQuery } from '@mui/material';

const App = (context) => {
	const parallaxRef = useRef(null);
	const isBrowser = useMediaQuery('(min-width:800px)');
	const extendedContext = { ...context, parallaxRef, isBrowser };

	return <div className="App">
		<Header { ...extendedContext }/>
		<Body { ...extendedContext }/>
	</div>;
};

export default App;
