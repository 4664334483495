import React from 'react';
import { useInView } from 'react-intersection-observer';
import ServiceAnimation from './ServiceAnimation.js';

const Services = (context) => {
	const { setState, data } = context;
	const { ref, inView } = useInView({
		threshold: 0.55,
		onChange: () =>
			inView && setState((prev) => ({ ...prev, active: data })),
	});

	return (
		<div ref={ ref } className="dot">
			<ServiceAnimation { ...context }/>
		</div>
	);
};

export default Services;
