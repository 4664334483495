import React from 'react';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import { map } from '@laufire/utils/collection';
import pages from '../data/pages';

// eslint-disable-next-line react/display-name
const getPage = (context) => ({ name, props }, i) => {
	const Component = pages[name];

	return <ParallaxLayer key={ i } { ...props }>
		<Component { ...{ ...context, data: props.offset } }/>
	</ParallaxLayer>;
};

const Body = (context) => {
	const { parallaxRef, config: { pageItems }} = context;
	const totalPages = pageItems.length - 1;

	return (
		<div className="body">
			<Parallax ref={ parallaxRef } pages={ totalPages }>
				{map(pageItems, getPage(context))}
			</Parallax>
		</div>
	);
};

export default Body;
