import React from 'react';
import { map } from '@laufire/utils/collection';
import generateShapes from '../../services/generateShapes';
import Shape from './Shape';

const Shapes = (context) => {
	const shapes = generateShapes(context);

	return map(shapes, (shape, i) =>
		<Shape key={ i } { ...{ ...context, data: shape } }/>);
};

export default Shapes;
