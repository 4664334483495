import React from 'react';
import { map } from '@laufire/utils/collection';

const heading = ['Talent Sourcing:',
	'Screening and Assessment: ',
	'Offer Negotiation:',
	'Onboarding Support:'];

const contents = [` We identify,
	attract, and engage with the best candidates in your industry.`, ` Our rigorous evaluation process
	ensures you get the right fit.`, ` We assist in securing top talent
	with competitive offers.`, ` We ensure
	a smooth transition for your new hires.`];

const Recruitment = () =>
	<div className="centerPara">
		<div>Recruitment</div>
		<div className="service">
			{map(contents, (content, i) =>
				<p key={ i } className="">
					<span className="bolding">{heading[i]}</span>
					{content}
				</p>)}
		</div>
	</div>;

export default Recruitment;
