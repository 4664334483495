import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { map } from '@laufire/utils/collection';
import Recruitment from './Slides/Recruitment';
import Staffing from './Slides/Staffing';
import Custom from './Slides/Custom';

const services = [Custom, Recruitment, Staffing];

const swiperProps = {
	spaceBetween: 30,
	centeredSlides: true,
	autoplay: {
		delay: 7000,
		disableOnInteraction: true,
	},
	pagination: { clickable: true },
	navigation: true,
	modules: [Pagination, Navigation, Autoplay],
	className: 'glassSlider',
};

const Service = (Content, i) => <SwiperSlide key={ i }>
	<div className="sliderContent">
		<Content/>
	</div>
</SwiperSlide>;

const Services = () =>
	<div className="servicesContent center">
		<Swiper { ...swiperProps }>
			{map(services, Service)}
		</Swiper>
	</div>;

export default Services;
