import { map } from '@laufire/utils/collection';
import React from 'react';

const Image = ({ alt, image }, index) =>
	<div key={ index } className="technology">
		<img
			className="technologyImage"
			title={ alt }
			src={ image }
			alt={ alt }
		/>
	</div>;

const TechImages = ({ config: { technologies }}) =>
	<div>
		{map(technologies, Image)}
	</div>;

export default TechImages;
