import React from 'react';

const Content = () =>
	<div className="home">
		<h1 className="homeHeading">
			Premium quality. Affordable prices.
		</h1>
		<div className="motto">
			<p>Our motto</p>
			<h4> Clarity in Simplicity. Transparency in Action. </h4>
		</div>
	</div>;

export default Content;
