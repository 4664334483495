import React from 'react';
import { map } from '@laufire/utils/collection';

const contents = ['As tech partners',
	'On fixed-bid engagements',
	'As a team on T&M at USD 25/hour/person'];

const Custom = () =>
	<div className="centerPara">
		<div>Custom Software Development</div>
		<div className="service">
			<p><span className="bolding">
				We build full-stack applications on the cloud:

			</span></p>
			{map(contents, (content, i) =>
				<p key={ i } className="tab">
					{content}

				</p>)}

		</div>
	</div>;

export default Custom;
