/* eslint-disable no-magic-numbers */
import { map, range } from '@laufire/utils/collection';
import { rndBetween } from '@laufire/utils/lib';
import { rndValue } from '@laufire/utils/random';

const divider = 10;
const modifiers = {
	x: (args) => rndBetween(...args),
	y: (args) => rndBetween(...args),
	rotation: (args) => rndBetween(...args),
	size: (args) => rndBetween(...args) / divider,
	offset: (args) => rndBetween(...args) / divider,
	speed: (args) => rndBetween(...args) / divider,
	position: (args) => rndValue(args),
	type: (args) => rndValue(args),
};

const generateShape = (shapes) =>
	map(modifiers, (val, key) => modifiers[key](shapes[key]));

const generateShapes = ({ config: { shapes, shapesCount }}) =>
	map(range(0, shapesCount), () => generateShape(shapes));

export default generateShapes;
